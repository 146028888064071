// ** Auth Endpoints
export default {
  // baseUrl: 'http://localhost:8001/api/', //LOCAL
  baseUrl: 'https://semaforos.colbiz.com.br/api/', //HOMOLOGAÇÃO
  loginEndpoint: '/login',
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
